<template>
  <v-footer padless class="mt-6">
    <v-card flat tile class="grey lighten-5 text-center" width="100%">
      <v-card-text>
        <v-btn
          v-for="item in links"
          :key="item.icon"
          class="mx-4"
          icon
          :href="item.link"
        >
          <v-icon size="24px">
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="pt-0" style="margin: 30px auto; max-width: 1200px">
        За допомогою сервісу Ви зможете вести облік нарахувань із утримання та управління будинком,
        комунальних послуг, а також інших послуг чи статтей витрат. Ця платформа дозволить автоматизувати роботу
        бухгалтерам та менеджерам управляючих компаній та ОСББ
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        {{ new Date().getFullYear() }} — <strong><a href="/" class="grey--text text--darken-1" style="text-decoration: none;">WeToo | ВіТу</a></strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      links: [
        {
          icon: 'mdi-facebook',
          link: 'https://www.facebook.com/platformwetoo'
        },
        {
          icon: 'mdi-twitter',
          link: 'https://twitter.com/WeTooPlatform'
        },
        {
          icon: 'mdi-linkedin',
          link: 'https://www.linkedin.com/in/wetooplatform'
        },
        {
          icon: 'mdi-instagram',
          link: 'https://www.instagram.com/wetooplatform'
        },
        {
          icon: 'mdi-youtube',
          link: 'https://www.youtube.com/channel/UCtqnE9Gk23Rt2-BkisIBgiQ'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
